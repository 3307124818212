import './styles/app.scss';

import { startStimulusApp } from '@symfony/stimulus-bridge';
import { Datepicker } from "vanillajs-datepicker";
import {Turbo} from "@hotwired/turbo-rails";
import Modal from "./components/modal";
import * as Sentry from "@sentry/browser";

/**
 * Настройки для страниц публичной части сайта.
 */

const config = document.getElementById('sentry');

if (!!config) {
    Sentry.init({
        dsn: config.dataset['dsn'],
        environment: config.dataset['runtime_environment'],
        tracesSampleRate: config.dataset['traces_sample_rate']
    });
}

Datepicker.locales.ru = {
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
    daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Сегодня",
    clear: "Очистить",
    format: "dd.mm.yyyy",
    weekStart: 1,
    monthsTitle: 'Месяцы'
};

// eslint-disable-next-line
startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.(j|t)sx?$/
));

if (!window.Turbo) {
    require("@hotwired/turbo-rails")
}

const mercureUrlElement = document.getElementById("mercure-url");

if (mercureUrlElement !== null) {
    const url = JSON.parse(mercureUrlElement.textContent);
    const eventSource = new EventSource(url);

    eventSource.onmessage = async function (message) {
        const json = JSON.parse(message.data);
        if (json.id === 'notification') {
            let options = {
                type: 'alert',
                callback: () => {
                    fetch('/profile/notification/mark-read/'+json.data.id, {
                        method: 'POST',
                    });
                },
            };

            if (json.data.hasOwnProperty('options')) {
                options = {...options, ...json.data.options};
            }

            const modal = new Modal(
                json.data.title,
                json.data.message,
                options
            );

            modal.show();

            let response = await fetch('/profile/notification/latest?stream=1');

            if (response.status === 200) {
                const html = await response.text();
                Turbo.renderStreamMessage(html);
            }
        }
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('notification[title]') && searchParams.has('notification[message]')) {
        const options = {
            type: 'alert'
        };

        if (searchParams.has('notification[type]')) {
            options.type = searchParams.has('notification[type]');
        }


        const modal = new Modal(
            searchParams.get('notification[title]'),
            searchParams.get('notification[message]'),
            options
        );

        modal.show();

        const url = new URL(window.location)
        url.searchParams.delete('notification[title]')
        url.searchParams.delete('notification[message]')

        history.pushState(null, '', url);
    }
}